<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <el-cascader
            size="small"
            v-model="searchForm.class_ids"
            :options="TreeData"
            :props="{value:'id',label:'name',emitPath: false}"
            :show-all-levels="false"
            clearable></el-cascader>
        <el-input placeholder="工号或姓名" v-model="searchForm.keyword" size="small" class="input-with-select"
                  style="width:200px;margin-left: 10px;" clearable></el-input>
        <el-select v-model="searchForm.year" size="small" clearable placeholder="选择年份" style="margin-left: 10px;">
          <el-option v-for="year in getYears" :label="year + '年'" :value="year" :key="year"></el-option>
        </el-select>

        <el-date-picker
            v-model="date_range"
            type="daterange"
            value-format="yyyy-MM-dd"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期" style="margin-left: 10px;">
        </el-date-picker>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                   @click="() => { page.current_page = 1; getList()}">搜索</el-button>
        <el-button icon="el-icon-plus" type="primary" size="small" plain @click="addNew">新增</el-button>
      </div>
      <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">
      </div>
    </el-row>
    <div class="dividerBar"></div>
    <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" width="55" label="序号"></el-table-column>
      <el-table-column prop="class_name" label="系部"></el-table-column>
      <el-table-column prop="loginname" label="工号" width="200"></el-table-column>
      <el-table-column prop="teacher_name" label="指导教师"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column prop="param_desc" label="材料类型"></el-table-column>
      <el-table-column prop="plan_desc" label="材料内容" show-overflow-tooltip width="300"></el-table-column>
      <el-table-column prop="plan_time" label="时间"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-tag class="pointer" type="primary" size="mini" @click="viewInfo(scope.row); isView = true">查看</el-tag>
          <el-tag class="pointer ml10" type="success" size="mini" @click="viewInfo(scope.row); isView = false">编辑</el-tag>
          <el-tag class="pointer ml10" type="danger" size="mini" @click="deleteJh(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
                   :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
                   :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <el-dialog :title="dialogTitle" :visible.sync="dialogshow" destroy-on-close :close-on-click-modal="false">
      <el-form :model="EditItem" :rules="rules" ref="formData" label-width="120px">
        <el-form-item label="系部" prop="class_id">
          <el-cascader
              v-model="EditItem.class_id"
              :options="TreeData"
              :props="{value:'id',label:'name',emitPath: false}"
              :show-all-levels="false"
              :disabled="isView"
              clearable></el-cascader>
        </el-form-item>
        <el-form-item label="指导教师" >
          <el-input v-model="$store.state.teacher.username" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="EditItem.title" autocomplete="off" placeholder="请输入标题" :disabled="isView"></el-input>
        </el-form-item>
        <el-form-item label="时间" prop="plan_time">
          <el-date-picker
              v-model="EditItem.plan_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              :disabled="isView"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="材料类型" prop="type">
          <el-select v-model="EditItem.type" placeholder="请选择材料类型" :disabled="isView">
            <el-option v-for="type in  materialTypes"
                       :label="type.param_desc"
                       :value="type.param_value"
                       :key="type.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="材料内容" prop="plan_desc">
          <el-input type="textarea"
                    v-model="EditItem.plan_desc"
                    autocomplete="off"
                    :autosize="{minRows: 2}"
                    :disabled="isView"
                    placeholder="材料内容"></el-input>
        </el-form-item>
        <el-form-item label="附件">
          <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
               v-for="(img, idx) in EditItem.files" :key="idx"
               v-if="img.ext == 'jpg' || img.ext == 'jpeg' || img.ext == 'png' || img.ext == 'gif'">
            <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                      :preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

            <div v-if="!isView" class="imgremove" @click="removeImg(idx)">
              <i class="el-icon-remove"></i>
            </div>
          </div>

          <div style="display:inline-block;vertical-align: top;">
            <el-upload style="display: inline-block; margin-right: 20px" action :http-request="uploadOss"
                       :on-success="uploadFiles" :show-file-list="false" name="image">
              <div v-if="!isView" class="imgaddbtn">＋</div>
            </el-upload>
          </div>
          <div style="line-height: normal;margin-top: 10px;"
               v-for="(img, idx) in EditItem.files" :key="idx"
               v-if="img.ext != 'jpg' && img.ext != 'jpeg' && img.ext != 'png' && img.ext != 'gif'">
            <i v-if="!isView" class="el-icon-remove" style="cursor: pointer;margin-right: 10px;" @click="removeImg(idx)"></i>
            <el-link type="primary" @click="downloadFile(img)">{{ img.fileName }}</el-link>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogshow = false">取 消</el-button>
        <el-button v-if="!isView" type="primary" @click="saveJh">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
export default {
  components: {tchtree},
  data() {
    return {
      thisTeacherid: 0,
      isView: false,
      dialogshow: false,
      date_range_value: "",
      searchForm: {
        class_ids: "",
        keyword: "",
        month: ""
      },
      EditItem: {
        id: 0,
        class_id: "",
        title: "",
        plan_desc: "",
        plan_time:  "",
        type: "",
        files:  [],
      },
      DataList: [],
      listForm: [],
      listState: [],
      ClassList: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      replaceMonths: {
        "jan": 1,
        "feb": 2,
        "mar": 3,
        "apr": 4,
        "may": 5,
        "jun": 6,
        "jul": 7,
        "aug": 8,
        "sep": 9,
        "oct": 10,
        "nov": 11,
        "dec": 12,
      },
      date_range: "",
      dialogTitle: "",
      TreeData: [],
      materialTypes:  [],
      rules: {
        class_id: [{required: true, message: "请选择班级", trigger: 'blur'}],
        title: [{required: true, message: "请输入标题", trigger: 'blur'}],
        plan_time: [{required: true, message: "请选择时间", trigger: 'change'}],
        plan_desc: [{required: true, message: "请输入材料内容", trigger: 'blur'}],
        type: [{required: true, message: "请选择材料类型", trigger: 'change'}],
      }
    };
  },
  mounted() {
   // this.getList();
    this.getOrgTree()
    this.getComType()
  },
  computed: {
    getYears() {
      let nowYear = new Date().getFullYear()
      let years = []
      for (let i = 0; i < 4; i++) {
        years.push(nowYear - i)
      }
      return years
    }
  },
  methods: {
    getComType() {
      this.$http
          .post("/api/sys_param_list", {
            param_type: `'MATERIAL_TYPE_${this.searchForm.month}'`,
            used: 0
          })
          .then(res => {
            this.materialTypes = res.data
          });
    },
    getList() {
      let startDate = ""
      let endDate = ""
      if (this.date_range) {
        startDate = this.date_range[0]
        endDate = this.date_range[1]
      }
      this.$http
        .post("/api/teacher_special_material_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          keyword: this.searchForm.keyword,
          month: this.searchForm.month,
          year: this.searchForm.year,
          class_ids: this.searchForm.class_ids,
          start_date: startDate,
          end_date: endDate,
        })
        .then(res => {
          this.DataList = res.data.data;
          this.page = res.data.page;
        });
    },
    deleteJh(row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http
            .post("/api/teacher_special_material_delete", { id: row.id })
            .then(res => {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
            });
          this.getList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    addNew() {
      this.isView = false
      this.EditItem = {
        id: 0,
        class_id: "",
        title: "",
        plan_desc: "",
        plan_time:  "",
        type: "",
        files:  [],
      }
      this.$nextTick(() => {
        this.$refs['formData'].clearValidate()
      })
      this.dialogshow = true;
      this.dialogTitle = this.searchForm.month + "月材料添加"
    },
    saveJh() {
      this.$refs['formData'].validate((valid) => {
        if (valid) {
          let EditItem =  {...this.EditItem}
          if (EditItem.files.length) {
            EditItem.files = EditItem.files.map(f => {
              return {
                fileName: f.fileName,
                fileUrl: f.src|| f.fileUrl,
              }
            })
          }
          EditItem.files = JSON.stringify(EditItem.files)
          EditItem.month = this.searchForm.month
          this.$http.post('/api/teacher_special_material_edit', EditItem).then(res => {
            this.$message.success('保存成功')
            this.$refs['formData'].resetFields()
            this.EditItem.files = []
            this.dialogshow = false;
            this.getList()
          })
        } else {
          return false;
        }
      });
    },
    viewInfo(row) {
      if (typeof row.files != 'object') {
        row.files = JSON.parse(row.files)
        row.files.map(f => {
          f.ext = f.fileName.substring(f.fileName.lastIndexOf('.')+1)
        })
      }
      row.type = row.type.toString()
      this.dialogshow = true;
      this.dialogTitle = this.searchForm.month + (this.isView ? '月材料详情' : '月材料修改')
      this.$nextTick(() => {
        this.EditItem = { ...row };
      })
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_back_to_school", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    getOrgTree() {
      this.$http.post("/api/teacher_class_tree", {biye:0}).then(res => {
        let data = res.data.tree
        let getXibu = (data) => {
          for (let item of data) {
            if (item.type == 'yx') {
              item.children = null;
            }
            if (item.children && item.children.length > 0) {
              getXibu(item.children)
            }
          }
          return data
        }
        this.TreeData = getXibu(data[0].children)
      })
    },
    uploadFiles(e) {
      e.ext = e.fileName.substring(e.fileName.lastIndexOf('.')+1).toLowerCase()
      this.EditItem.files.push(e);
    },
    removeImg(idx) {
      this.EditItem.files.splice(idx, 1);
    },
    downloadFile(file) {
      this.$http.post('/api/download_file', {fileUrl: file.fileUrl || file.src}, {
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res.data]);
        const elink = document.createElement('a');
        elink.download = file.fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      })
    },
  },
  beforeRouteEnter(to,from,next) {
    next(vm => {
      let m = to.path.substring(to.path.lastIndexOf('_')+1)
      m = vm.replaceMonths[m]
      vm.searchForm = {
        class_ids: "",
        keyword: "",
        month: m,
        year: "",
      }
      vm.page.current_page = 1
      vm.date_range = ""
      vm.getList()
      vm.getComType()
    })
  }
};
</script>

<style scoped>
.pageContainer >>> .el-textarea.is-disabled .el-textarea__inner,
.pageContainer >>> .el-input.is-disabled .el-input__inner {
  background-color: unset;
  color: unset;
}
.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}

.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
